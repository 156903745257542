import WebServiceRequest from "./WebServiceRequest";

class CreateFakeUserLevel extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("FakeUserLevels/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class UpdateFakeUserLevel extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("FakeUserLevels/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetAllFakeUserLevels extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("FakeUserLevels/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class GetFakeUserLevelBy extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("FakeUserLevels/GetBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class DeleteFakeUserLevel extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("FakeUserLevels/Delete");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export {
  CreateFakeUserLevel,
  UpdateFakeUserLevel,
  GetAllFakeUserLevels,
  GetFakeUserLevelBy,
  DeleteFakeUserLevel,
};
