<template>
  <validation-provider
    #default="{ errors }"
    :name="inputId"
    :vid="inputId"
    :rules="customRules"
  >
    <b-form-group :label="inputLabel" :label-for="inputId">
      <b-input-group>
        <b-form-input
          trim
          :id="inputId"
          :value="value"
          :state="errors.length > 0 ? false : null"
          class="d-inline-block"
          :placeholder="inputPlaceholder"
          @input="$emit('input', $event)"
          @keydown.enter="emitEnterKeydown"
        />
        <b-input-group-append :is-text="isText">
          <slot name="append"></slot>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  emits: ["enter"],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    isText: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, null],
      default: "This field is required.",
    },
    customRules: {
      type: [String, null],
      default: null,
    },
    inputId: {
      type: String,
      required: false,
      default: "textValue",
    },
    value: {
      type: [String, Number, null],
      default: null,
    },
    inputLabel: {
      type: [String, null],
      default: "Enter",
    },
    inputPlaceholder: {
      type: [String, null],
      default: "Enter",
    },
  },
  methods: {
    emitEnterKeydown() {
      this.$emit("enter");
    },
  },
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    ValidationProvider,
  },
};
import { ValidationProvider } from "vee-validate";
import {
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from "bootstrap-vue";
</script>
