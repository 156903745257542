<template>
  <BRow>
    <BCol cols="12">
      <BForm @submit.prevent>
        <BFormGroup :label="inputLabel" :label-for="inputId">
          <VSelect
            :disabled="isDisabled"
            :id="inputId"
            :appendToBody="isInDialog"
            :loading="isLoading"
            :calculatePosition="calculatedPosition"
            :filterable="false"
            v-if="items"
            :reduce="reduce"
            :options="items"
            :label="title"
            :closeOnSelect="closeOnSelect"
            :multiple="multiple"
            :selectable="selectable"
            :placeholder="placeholder"
            :value="value"
            @input="$emit('input', $event)"
            @search="(search, loadingFn) => $emit('search', search, loadingFn)"
            @open="open"
            @close="close"
          >
            <template #option="data">
              <slot name="labelOption" :item="data">
                {{ data[title] }}
              </slot>
            </template>
            <template #no-options>
              <slot name="no-options"> هیچ آیتمی جهت نمایش وجود ندارد </slot>
            </template>
            <template #list-footer>
              <slot name="list-footer"></slot>
            </template>
          </VSelect>
        </BFormGroup>
      </BForm>
    </BCol>
  </BRow>
</template>
<script>
export default {
  emits: ["open", "close"],
  props: {
    defaultSelectedItems: {
      type: [Number, String, Array],
      required: false,
      default: null,
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    title: {
      type: String,
      default: "title",
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    reduce: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    inputLabel: {
      type: String,
      default: "Select",
    },
    inputId: {
      type: String,
      default: "selectForm",
    },
    value: {
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isInDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {};
  },
  methods: {
    open() {
      this.$emit("open");
    },
    close() {
      this.$emit("close");
    },
    calculatedPosition(dropdownList, _, { width, top, left }) {
      if (this.isInDialog) {
        dropdownList.style.width = width;
        dropdownList.style.top = top;
        dropdownList.style.right = left;
        dropdownList.style["z-index"] = 9999999;
        dropdownList.style["max-height"] = "30%";
      } else {
        dropdownList.style.top = top;
        dropdownList.style.left = left;
        dropdownList.style.width = width;
      }
    },
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    VSelect,
  },
};
import { BRow, BCol, BForm, BFormGroup } from "bootstrap-vue";
import VSelect from "vue-select";
</script>
<style>
@media only screen and (min-width: 768px) {
  .custom-v-select-dropdown-transform {
    transform: translateX(-50%) !important;
  }
}
</style>
