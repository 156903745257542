<template>
  <b-modal
      title="Transfer Tron"
      id="transfer-tatum"
      centered
      ok-title="Submit"
      cancel-title="Dismiss"
      :ok-disabled="isLoading"
      no-close-on-backdrop
      ok-variant="success"
      no-close-on-ok
      @hidden="resetModal"
      @ok.prevent="okCallback(amount)"
  >
    <b-overlay :show="isLoading">
      <b-container fluid>
        <b-form-row>
          <b-col cols="12" class="mt-2">
            <text-input
                v-model="amount"
                inputId="amount"
                inputLabel="Amount"
                inputPlaceholder="3 tron"
            ></text-input>
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import TextInput from "@/views/components/Utilities/TextInput.vue";
import CustomAppendText from "@/views/components/Utilities/CustomAppendText.vue";
import {
  BModal,
  BOverlay,
  BContainer,
  BCol,
  BFormRow,
  BFormGroup,
  BButton,
  BAlert
} from "bootstrap-vue";
export default {
  name:'TransferTatumModal',
  props: {
    okCallback: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      amount:''
    };
  },
  methods: {
    resetModal() {
      this.amount = '';
    },
  },
  components: {
    BModal,
    BOverlay,
    BContainer,
    BCol,
    BFormRow,
    BFormGroup,
    BButton,
    TextInput,
    CustomAppendText,
    BAlert
  },
};
</script>
