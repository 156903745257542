import WebServiceRequest from "../Api/WebServiceRequest";
class AccountSetSliderRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/SetSlider");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class AccountGetSliderRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/GetSliders");
  }
}
class AccountDeleteSliderRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setPriority(priority) {
    super.setTag("Account/DeleteSlider?priority=" + priority);
  }
}

class AccountAddUserByAdminRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/AddUserByAdmin");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetTokenByGoogleCode extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/GetTokenByGoogleCode");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class DisableGoogleAuthenticator extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/DisableGoogleAuthrnticator");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class AccountCreateFakeUser extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/CreateFakeUser");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export {
  AccountSetSliderRequest,
  AccountGetSliderRequest,
  AccountDeleteSliderRequest,
  AccountAddUserByAdminRequest,
  GetTokenByGoogleCode,
  DisableGoogleAuthenticator,
  AccountCreateFakeUser,
};
