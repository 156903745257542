<template>
  <b-modal
    title="Create Fake users"
    id="create-new-fake-users"
    centered
    ok-title="Create"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    no-close-on-backdrop
    ok-variant="success"
    no-close-on-ok
    @hidden="resetModal"
    @ok.prevent="okCallback(request)"
  >
    <b-overlay :show="isLoading">
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <strong>Notice :</strong>
            <span>These users credit will be 0.1 ~ 3 Tron </span>
          </b-col>
          <b-col cols="12" class="mt-2">
            <text-input
              v-model="request.count"
              inputId="count"
              inputLabel="Count of users"
              inputPlaceholder="3"
            ></text-input>
          </b-col>
          <b-col cols="12" class="mt-2">
            <b-form-group label="country" label-for="countryId">
              <v-select
                class="w-auto"
                id="countryId"
                v-model="request.countryId"
                :options="countries"
                :clearable="false"
                label="name"
                :reduce="(name) => name.id"
              />
            </b-form-group>
          </b-col>
          <BCol cols="12" class="mt-2">
            <FakeUserLevelPicker
              @getLevel="setFakeUserLevel"
            ></FakeUserLevelPicker>
          </BCol>
          <b-col cols="12" class="mt-2">
            <text-input
              v-model="request.minValue"
              inputId="minValue"
              inputLabel="Min wallet credit"
              inputPlaceholder="1"
            ></text-input>
          </b-col>
          <b-col cols="12" class="mt-2">
            <text-input
              v-model="request.maxValue"
              inputId="maxValue"
              inputLabel="Max wallet credit"
              inputPlaceholder="1"
            ></text-input>
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import TextInput from "@/views/components/Utilities/TextInput.vue";
import CustomAppendText from "@/views/components/Utilities/CustomAppendText.vue";
import FakeUserLevelPicker from "@/views/components/FakeUserLevels/FakeUserLevelPicker.vue";
import {
  BModal,
  BOverlay,
  BContainer,
  BCol,
  BFormRow,
  BFormGroup,
  BButton,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  props: {
    okCallback: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      request: {
        count: "",
        countryId: "",
        minValue: "",
        maxValue: "",
        fakeUserLevelId: "",
      },
      countries: [
        {
          id: 1,
          name: "USA",
        },
        {
          id: 2,
          name: "Iran",
        },
        {
          id: 3,
          name: "UK",
        },
        {
          id: 4,
          name: "UAE",
        },
      ],
    };
  },
  methods: {
    setFakeUserLevel(selectedLevel) {
      if (selectedLevel) {
        this.request.fakeUserLevelId = selectedLevel.id;
      } else {
        this.request.fakeUserLevelId = null;
      }
    },
    resetModal() {
      this.request.count = "";
      this.request.countryId = "";
    },
  },
  components: {
    BModal,
    BOverlay,
    BContainer,
    BCol,
    BFormRow,
    BFormGroup,
    BButton,
    TextInput,
    CustomAppendText,
    FakeUserLevelPicker,
    BAlert,
    vSelect,
  },
};
</script>
