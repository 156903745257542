<template>
  <CustomPicker
    :isInDialog="isInDialog"
    :isLoading="isLoading"
    :closeOnSelect="true"
    :placeholder="placeholder"
    title="name"
    :inputLabel="inputLabel"
    v-model="selectedFakeUserLevel"
    :items="fakeUserLevelsList"
    @search="() => {}"
  >
  </CustomPicker>
</template>
<script>
export default {
  emits: ["getLevel"],
  props: {
    defaultSelectedGroupClass: {
      type: Object,
      required: false,
    },
    isInDialog: {
      type: Boolean,
      default: false,
    },
    inputLabel: {
      type: String,
      default: "User level",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select User Level",
    },
  },
  data() {
    return {
      isLoading: false,
      fakeUserLevelsList: null,
      totalCount: null,
      selectedFakeUserLevel: null,
      fakeUserLevelFilters: {
        pageNumber: 1,
        count: 50,
      },
    };
  },
  watch: {
    selectedFakeUserLevel: {
      handler(val) {
        this.$emit("getLevel", val);
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAllFakeUserLevels();
  },
  methods: {
    async getAllFakeUserLevels() {
      try {
        this.isLoading = true;
        const _this = this;
        const getAllFakeUserLevels = new GetAllFakeUserLevels(_this);
        getAllFakeUserLevels.setRequestParam(_this.fakeUserLevelFilters);
        await getAllFakeUserLevels.fetch((res) => {
          _this.fakeUserLevelsList = res.data.data;
          _this.totalCount = res.data.total;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    CustomPicker,
  },
};
import CustomPicker from "@/views/components/Utilities/CustomPicker.vue";
import { GetAllFakeUserLevels } from "@/libs/Api/FakeUserLevels";
</script>
